@use "../../../../../styles/mixins";
@use "../../../../../styles/variables.module" as *;

$minimum-message-height: 300px;

.messageTransitionContainer {
  position: relative;
  min-height: $minimum-message-height;
  overflow-y: hidden;

  @include mixins.translateTransition(translateX(-20%), translateX(0));
  @include mixins.opacityTransition(0, 1);
}

.videoTransitionContainer {
  @include mixins.translateTransition(translateX(20%), translateX(0));
  @include mixins.opacityTransition(0, 1);
}

.aboutBlockContainer {
  text-align: left;
  display: flex;
  justify-content: space-between;
  column-gap: 5%;

  &EditMode {
    column-gap: 2%;
    justify-content: flex-start;

    > *:last-of-type {
      flex-basis: 45%;
      margin-top: 20px;
    }
  }

  > * {
    flex-basis: 50%;
  }

  @media(max-width: $break-point-md) {
    flex-wrap: wrap;

    > * {
      &:nth-of-type(1) {
        flex-basis: 100%;
        margin-bottom: 2rem;
      }
      &:nth-of-type(2) {
        flex-basis: 100%;
      }
    }
  }

  &MessageFullWidth {
    > * {
      &:nth-of-type(1) {
        flex-basis: 100%;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
}

.messageContainer {
  color: $black;
  margin-right: 10px;
  vertical-align: middle;
  overflow-wrap: break-word;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .25s;
  transform: translateX(-20%);
  opacity: 0;
}
.titleContainer {
  color: $black;
  header{
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.videoContainer {
  color: $black;
  vertical-align: middle;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .5s;
  flex-basis: 40%;
  transform: translateX(20%);
  opacity: 0;
  height: 100%;
}

.editMediaContainer {
  font-size: 1.4rem;
  background-color: #E7E7E7;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

.seeMore {
  position: absolute;
  z-index: 10;
  text-align: center;
  font-size: 1rem;
  bottom: 0;
  height: 50px;
  width: 100%;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .25s;
  transform: translateX(-20%);
  opacity: 0;

  button {
    position: absolute;
    margin-top: 10px;
    padding: 3px 7px;
    border-radius: 10px;
    background: var(--blocks);
    color: var(--blocksTextColor);
    left: 50%;
    transform: translateX(-50%);
  }
}

:export {
  minimum-message-height: $minimum-message-height
}
